export { default as ilsBackups } from './backups.svg';
export { default as ilsDevices } from './devices.svg';
export { default as ilsDocuments } from './documents.svg';
export { default as ilsExpense } from './expense.svg';
export { default as ilsFaq } from './faq.svg';
export { default as ilsPayment } from './payment.svg';
export { default as ilsProducts } from './products.svg';
export { default as ilsSafe } from './safe.svg';
export { default as ilsSecure } from './secure.svg';
export { default as ilsSupplier } from './supplier.svg';
export { default as ilsSupport } from './support.svg';
export { default as ilsUpdates } from './updates.svg';
export { default as ilsUser } from './users.svg';
