import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Pane, Icon, UnorderedList, ListItem } from '@muhsis/ui'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'

import { theme } from '../theme'
import { Layout, SEO, BackgroundImageGrid } from '../components'
import {
  Header,
  Footer,
  FeatureItem,
  FeatureMain,
  SpotlightHeading,
  SpotlightLight,
  SpotlightDark,
  Support,
} from '../blocks'
import { imgFeatureReceiptDesigner } from '../assets/images'
import {
  ilsDocuments,
  ilsSupplier,
  ilsExpense,
  ilsPayment,
  ilsProducts,
  ilsSafe,
  ilsBackups,
  ilsUpdates,
  ilsUser,
} from '../assets/illustrations'

const ScRow = styled(Row)`
  border-left: 1px solid ${theme.colors.border.default};
  border-top: 1px solid ${theme.colors.border.default};
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`

const ScBorderedPane = styled(Pane)`
  border-right: 1px solid ${theme.colors.border.default};
  border-bottom: 1px solid ${theme.colors.border.default};
  min-height: 320px;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 2rem 2rem 1rem;
`

const FeaturesPage = () => (
  <Layout>
    <SEO
      title="Ürün Özellikleri"
      keywords={[
        `fatura, irsaliye yönetimi`,
        `cari hesap yönetimi`,
        `gider yönetimi`,
        `kasa ve banka hesapları yönetimi`,
        `ödeme ve tahsilat yönetimi`,
        `stok yönetimi`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Header />
      <SpotlightLight
        title="Online Ön Muhasebe ve Finans Yönetim Programı"
        description="Muhsis web tabanlı bir ön muhasebe ve finans yazılımıdır. Uygulamamız ve tüm finansal verileriniz, güvenli bulut mimarisinde saklanmaktadır. Muhsis'e dilediğiniz yerden, dilediğiniz cihazdan (bilgisayar, telefon) erişebilirsiniz."
      />

      <Pane paddingY="2rem" textAlign="center">
        <Icon
          icon="double-chevron-down"
          color={lighten(0.3, theme.colors.text.muted)}
          size={24}
        />
      </Pane>

      <Pane marginY="2.5rem">
        <Grid>
          <ScRow>
            <Col sm={4}>
              <ScBorderedPane>
                <FeatureItem
                  image={ilsDocuments}
                  title="Belge Yönetimi"
                  description="Fatura, irsaliye, sipariş, yurt dışı fatura, proforma
                fatura,iade fatura ve stok fişi gibi tüm alış ve satış
                belgelerini yönetebilirsiniz. Gelişmiş filtreleme sistemi ile
                aradığınız belgeye rahatlıkla ulaşırsınız. Belgeler arası
                dönüştürme yapmak çok kolaydır."
                />
              </ScBorderedPane>
            </Col>

            <Col sm={4}>
              <ScBorderedPane>
                <FeatureItem
                  image={ilsSupplier}
                  title="Cari Hesap Yönetimi"
                  description="Müşterileriniz ve tedarikçileriniz için ayrıntılı bilgiler
                  içeren cari kartlarınızı Muhsis ile yönetebilirsiniz. Muhsis,
                  müşterileriniz için gerekli olan muhasebe kayıtlarını otomatik
                  olarak oluşturur. Müşteri ve tedarikçilerinize e-posta ile
                  mutabakat mektubu, ekstre veya belge gönderebilirsiniz."
                />
              </ScBorderedPane>
            </Col>

            <Col sm={4}>
              <ScBorderedPane borderTopRightRadius="0.25rem">
                <FeatureItem
                  image={ilsExpense}
                  title="Gider Yönetimi"
                  description="Şirketinizin tüm masraf ve giderlerini Muhsis ile
                  yönetebilirsiniz. Giderlere ait tüm bilgiler ve rapolara
                  dilediğiniz zaman ulaşabilir, her ay hangi kalemlere harcama
                  yaptığınızı rahatlıkla görebilirsiniz."
                />
              </ScBorderedPane>
            </Col>

            <Col sm={4}>
              <ScBorderedPane borderBottomLeftRadius="0.25rem">
                <FeatureItem
                  image={ilsSafe}
                  title="Kasa ve Banka Hesapları"
                  description="Kasa hesaplarınızı, çalıştığınız tüm bankalardaki
                  vadeli/vadesiz, yurtiçi/yurtdışı hesaplarınızı, ayrıca kredi
                  hesaplarınızı, pos cihazlarınızı ve kredi kartlarınızı Muhsis
                  ile yönetebilir, ödeme ve tahsilatlarınızda kullanabilirsiniz."
                />
              </ScBorderedPane>
            </Col>

            <Col sm={4}>
              <ScBorderedPane>
                <FeatureItem
                  image={ilsPayment}
                  title="Ödeme ve Tahsilatlar"
                  description="Müşterilerinizden alacağınız ve tedarikçilerinize yapacağınız
                  tüm ödemelerinizi Muhsis ile yönetebilir, gelişmiş filtreleme
                  ve bildirim sistemi sayesinde, tarihi yaklaşan veya geçen
                  ödemelerinizi asla gözden kaçırmazsınız."
                />
              </ScBorderedPane>
            </Col>

            <Col sm={4}>
              <ScBorderedPane borderBottomRightRadius="0.25rem">
                <FeatureItem
                  image={ilsProducts}
                  title="Stok Yönetimi"
                  description="** Elinizde bulunan tüm ürünleri buradan yönetebilirsiniz.
                  Stok kartları ile ilgil; birim, KDV, muhasebe tablosu, fiyat
                  gibi tüm bilgileri güncelleyebilir, Hızlı Stok Kartı
                  özelliği ile beraber, ürün kataloğunuzu çok kısa sürede
                  Muhsis'e aktarabilirsiniz."
                />
              </ScBorderedPane>
            </Col>
          </ScRow>
        </Grid>
      </Pane>

      <Pane paddingTop="3rem" paddingBottom="4rem">
        <SpotlightHeading
          title="Tüm Paketlerimizde Bulunan Özellikler"
          description="Muhsis'te hangi paketi seçerseniz seçin, aşağıdaki özelliklerden faydalanabilirsiniz."
        />

        <Pane marginTop="2rem">
          <Grid>
            <Row>
              <Col sm={3}>
                <FeatureItem
                  image={ilsUser}
                  title="Sınırsız Kullanıcı"
                  description="Muhsis'te kullanıcı başına fiyat ödemezsiniz. Tüm ekibinizi
                  davet edin, Muhsis'i hep beraber kullanın."
                />
              </Col>

              <Col sm={3}>
                <FeatureItem
                  image={ilsDocuments}
                  title="Sınırsız Belge & İşlem"
                  description="Dilediğiniz kadar belge(fatura, irsaliye, vs.), ödeme,
              tahsilat, gider kaydı oluşturabilirsiniz. Muhsis'te işlem
              limiti yoktur."
                />
              </Col>

              <Col sm={3}>
                <FeatureItem
                  image={ilsBackups}
                  title="Günlük Yedekleme"
                  description="Tüm veriler her gün düzenli aralıklar ile bizim tarafımızdan
              yedeklenir. Veri kaybı durumunuzda ekibimiz memnuniyetle size
              yardımcı olacaktır."
                />
              </Col>

              <Col sm={3}>
                <FeatureItem
                  image={ilsUpdates}
                  title="Ücretsiz & Düzenli Güncellemeler"
                  description="Muhsis bulut tabanlı bir yazılım olduğu için tüm
              güncellemeleri anında kullanabilirsiniz. Uygulamaya eklenecek
              tüm yeni güncellemeler de fiyata dahildir."
                />
              </Col>
            </Row>
          </Grid>
        </Pane>
      </Pane>

      <Pane background="#F5FAFE" marginTop="5rem" paddingY="5rem">
        <FeatureMain
          orientation="left"
          image={imgFeatureReceiptDesigner}
          suptitle="FATURA TASARIM ARACI"
          title="Tüm Belge Türlerinizi Yazdırın"
          description="Fatura, irsaliye ve diğer tüm belgeleriniz için, belge tasarım aracımız ile fatura tasarımınıza uygun yazdırma şablonları hazırlayabilir ve belgelerinizi yazdırırken kullanabilirsiniz."
        >
          <Row>
            <Col sm={6}>
              <UnorderedList size={400} icon="tick">
                <ListItem>Sınırsız şablon</ListItem>
                <ListItem>Belge yazdırma</ListItem>
              </UnorderedList>
            </Col>

            <Col sm={6}>
              <UnorderedList size={400} icon="tick">
                <ListItem>Tasarım aracı</ListItem>
                <ListItem>Kolay kullanım</ListItem>
              </UnorderedList>
            </Col>
          </Row>
        </FeatureMain>
      </Pane>
    </BackgroundImageGrid>

    <SpotlightDark />
    <Support />
    <Footer />
  </Layout>
)
export default FeaturesPage
